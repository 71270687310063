

import React from "react";
import Header from "./common/Header"
import Services from './mainapp/Services'; 
import Customer from './mainapp/Customer';
 
import Community from './mainapp/Community';
import FAQ from './mainapp/FAQ';
import Footer from './common/Footer';
import Progress from "./mainapp/Progress";

class MainApp extends React.Component {
    componentDidMount() {
      
    }

    render() {
        return <div className="content-wrapper">

            <Header  bgclass="bg-navy"/>
            <Services />
            <Footer bgclass="bg-navy"/>
            <Progress />

        </div>
    }
}
export default MainApp;