 
function MenuItem(props) {

    const CommingSoon = () => <span className="badge gradient-2 rounded">comming soon</span>
    const LearnMore = () => <a className="mb-0 more hover" href="#sc_nodes">Learn more</a>

    return (
        <li className="dropdown-item mb-2">
            <div className="d-flex flex-row">
                <div className="nav-list-icon">                    
                    <img src={props.logo} className="svg-inject icon-svg icon-svg-xs text-primary me-3" alt="" />
                </div>
                <div className="lh-xs">
                    <h6 className="mb-0 lh-xs">{props.name}</h6>
                    {
                        props.more && <LearnMore />
                    }
                    {
                        props.comming && <CommingSoon />
                    }
                </div>
            </div>
        </li>)

}
export default MenuItem;