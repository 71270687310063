import { useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import { MESSAGE_FOR_SIGN } from "../../lib/constant";
import { API_HOST } from "../../lib/constant";
import $ from "jquery";

function VerifyComponent(props) {
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState("");
  const [network, setNetwork] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [balance, setBalance] = useState("");
  const [signature, setSignature] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [moonList, setMoonList] = useState([]);
  const [btcList, setBTCList] = useState([]);
  const [codes, setCodes] = useState([]);
  const [moonCount, setMoonCount] = useState(0);
  const [btcCount, setBtcCount] = useState(0);
  const [moonListHtml, setMoonListHtml] = useState("");
  const [btcListHtml, setBtcListHtml] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [bobListHtml, setBobListHtml] = useState("");
  const [bobList, setBobList] = useState([]);
  const [bobCount, setBobCount] = useState(0);
  const [loomListHtml, setLoomListHtml] = useState("");
  const [loomList, setLoomList] = useState([]);
  const [loomCount, setLoomCount] = useState(0);
  const [countCodes, setCountCodes] = useState([]);
  const [seqNo, setSeqNo] = useState(0);

  const checkUnisat = async () => {
    let unisat = window.unisat;

    for (let i = 1; i < 10 && !unisat; i += 1) {
      await new Promise((resolve) => setTimeout(resolve, 100 * i));
      unisat = window.unisat;
    }

    if (unisat) {
      setUnisatInstalled(true);
    } else if (!unisat) return;

    unisat.getAccounts().then((accounts) => {
      handleAccountsChanged(accounts);
    });

    unisat.on("accountsChanged", handleAccountsChanged);
    unisat.on("networkChanged", handleNetworkChanged);

    return () => {
      if (unisat && typeof unisat.removeListener === "function") {
        unisat.removeListener("accountsChanged", handleAccountsChanged);
        unisat.removeListener("networkChanged", handleNetworkChanged);
      }
    };
  };
  const connectUnisat = async () => {
    // 逻辑来连接 Unisat 钱包
    await checkUnisat();
    if (window.unisat) {
      const unisat = window.unisat;
      const result = await unisat.requestAccounts();

      handleAccountsChanged(result);
    }
  };
  const setMyCodes = async (cods) => {
    setCodes(cods);
  };
  const claimMyCode = async () => {
    setShowModal(true);
  };
  const formatShortAddress = (address) => {
    if (!address) {
      return "";
    }
    return address.slice(0, 6) + "..." + address.slice(-4);
  };
  const handleAccountsChanged = async (_accounts) => {
    if (accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }

    setAccounts(_accounts);
    if (_accounts.length > 0) {
      setConnected(true);
      setAddress(_accounts[0]);
      setNetwork("Unisat");
      setAccounts(_accounts);

      getBasicInfo();
    } else {
      setConnected(false);
      setAddress("");
      setNetwork("");
      setAccounts([]);
    }
  };
  const getBasicInfo = async () => {
    if (signature) {
      return;
    }
    const unisat = window.unisat;
    const [address] = await unisat.getAccounts();
    setAddress(address);

    const publicKey = await unisat.getPublicKey();
    setPublicKey(publicKey);
    const balance = await unisat.getBalance();
    setBalance(balance);
    const network = await unisat.getNetwork();
    setNetwork(network);
    try {
      let res = await unisat.getInscriptions(0, 200);
      console.log(res);
      const message = MESSAGE_FOR_SIGN;
      const signature = await window.unisat.signMessage(message);
      setSignature(signature);
      getMoonInscriptions(res.list, address, publicKey, signature);
    } catch (e) {
      console.log(e);
    }
  };

  const getMoonInscriptions = async (list, address, publicKey, signature) => {
    //get inscriptionId from list
    const inscriptionIds = list.map((inscription) => {
      return inscription["inscriptionId"];
    });
    console.log(inscriptionIds);
    console.log("newids");
    const ids = inscriptionIds.join(",");

    const postData = {
      ids: ids,
      address: address,
      signature: signature,
      chain: "btc",
      pubKey: publicKey,
    };
    const requestMetaData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };

    var url = API_HOST + "/verifyBTCToMoonList";
    const response = fetch(url, requestMetaData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const mList = data["data"]["list"];
        setMoonList();
        setCodes(data["data"]["codes"]);
        if(data["data"]["codes"] && data["data"]["codes"].length > 0) {
          setSeqNo(data["data"]["codes"][0]["seqno"]); 
        }
        setMoonList(mList);
        setMoonCount(mList.length);

        const html = getListHtml(mList, "BOB", "lg");
        setMoonListHtml(html);
      });

    url = API_HOST + "/verifyBTCList";
    const response2 = fetch(url, requestMetaData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const bList = data["data"]["list"];
        setBTCList(bList);

        setBtcCount(bList.length);
        const bListHtml = getListHtml(bList, "BOB");
        setBtcListHtml(bListHtml);
      });
    url = API_HOST + "/verifyHolderList";
    const response3 = fetch(url, requestMetaData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (
          data["data"]["list"]["bob"] &&
          data["data"]["list"]["bob"].length > 0
        ) {
          setBobList(data["data"]["list"]["bob"]);
          setBobCount(data["data"]["list"]["bob"].length);
          const bListHtml = getListHtml(data["data"]["list"]["bob"], "BOB");
          setBobListHtml(bListHtml);
        }
        if (
          data["data"]["list"]["starloom"] &&
          data["data"]["list"]["starloom"].length > 0
        ) {
          setLoomList(data["data"]["list"]["starloom"]);
          setLoomCount(data["data"]["list"]["starloom"].length);
          const lListHtml = getListHtml(
            data["data"]["list"]["starloom"],
            "Star LOOM"
          );
          setLoomListHtml(lListHtml);
        }
        if (
          data["data"]["codes"] &&
          data["data"]["codes"].length > 0
        ) {
          setSeqNo(data["data"]["codes"][0]["seqno"]);
          setCountCodes([{
            code: data["data"]["codes"][0]["code"],
            count:
              data["data"]["list"]["bob"].length +
              data["data"]["list"]["starloom"].length,
          }]);
        }
      });
  };

  const handleNetworkChanged = async (network) => {
    setNetwork(network);
    await getBasicInfo();
  };

  const getClickButton = () => {
    const verifyDisplay = connected ? "none" : "block";
    const claimDisplay = connected ? "block" : "none";
    return (
      <>
        {" "}
        <span style={{ display: verifyDisplay }}>
          <button
            onClick={connectUnisat}
            className="btn btn-lg btn-primary btn-icon btn-icon-end rounded-xl mx-1 scroll"
          >
            Verify Wallet <i className="uil uil-arrow-down-right"></i>
          </button>
        </span>
        <span style={{ display: claimDisplay }}>
          <button
            onClick={claimMyCode}
            className="btn btn-lg btn-fuchsia btn-icon btn-icon-end rounded-xl mx-1"
          >
            Claim My Code<i className="uil uil-arrow-up-right"></i>
          </button>
        </span>
      </>
    );
  };

  const getListHtml = (list, type, size) => {
    const html = list.map((moon) => {
      console.log(moon);
      var url = "https://unisat.io/inscription/" + moon["inscriptionId"];
      var sizeclass = "col-md-3 col-lg-2";
      if (size == "lg") {
        sizeclass = "col-md-6 col-lg-4";
      }
      return (
        <div className={sizeclass}>
          <figure
            className="lift rounded-xl mb-6"
            title='<h5 className="mb-0">Click to see the demo</h5>'
          >
            <a href={url} target="_blank">
              <img
                className="shadow-xl"
                src={moon.image}
                srcset={moon.image + " 2x"}
                alt=""
              />
            </a>
          </figure>
          <h2 className="fs-22 mb-0">
            <a href={url} target="_blank" className="link-dark">
              {type} {moon.tokenId}
            </a>
          </h2>
        </div>
      );
    });
    return html;
  };

  const getCodesHtml = (codes) => {
    const html = codes.map((code,index) => {
      return (
        <div className="form-floating mb-4">
          <input
            type="text"
            className="form-control"
            value={code["code"]}
            placeholder={code["code"]}
            id="codeValue"
            readOnly
          />
          <span className="password-toggle"><i id={"moon_copy_"+index} onClick={async()=>{
            await navigator.clipboard.writeText(code["code"]);
            $("#moon_copy_"+index).removeClass("uil-copy").addClass("uil-check");
          }} className="uil uil-copy"></i></span>
          <label htmlFor="codeValue">Code for BOB {code["mid"]}</label>
        </div>
      );
    });
    return html;
  };

  const getCountCodesHtml = (codes) => {
    const html = codes.map((code,index) => {
      return (
        <div className="form-floating mb-4">
          <input
            type="text"
            className="form-control"
            value={code["code"]}
            placeholder={code["code"]}
            id="codeValue"
            readOnly
          />
          <span className="password-toggle"><i id={"count_copy_"+index} onClick={async()=>{            
            await navigator.clipboard.writeText(code["code"]);
            $("#count_copy_"+index).removeClass("uil-copy").addClass("uil-check");
          }} className="uil uil-copy"></i></span>
          <label htmlFor="codeValue">
            Code for Holders owned {code["count"]} Inscriptions
          </label>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <section className="wrapper overflow-hidden">
        <div className="container pt-19 pt-md-21 pb-16 pb-md-18 text-center position-relative">
          <div
            className="position-absolute"
            style={{ top: "-12%", left: "50%", transform: "translateX(-50%)" }}
            data-cue="fadeIn"
          ></div>
          <div className="row position-relative">
            <div className="col-lg-8 col-xxl-7 mx-auto position-relative">
              <div
                className="position-absolute shape grape w-5 d-none d-lg-block"
                style={{ top: "-5%", left: "-15%" }}
                data-cue="fadeIn"
                data-delay="1500"
              ></div>
              <div
                className="position-absolute shape violet w-10 d-none d-lg-block"
                style={{ bottom: "30%", left: "-20%" }}
                data-cue="fadeIn"
                data-delay="1500"
              ></div>
              <div
                className="position-absolute shape fuchsia w-6 d-none d-lg-block"
                style={{ top: "0%", right: "-25%", transform: "rotate(70deg)" }}
                data-cue="fadeIn"
                data-delay="1500"
              ></div>
              <div
                className="position-absolute shape yellow w-6 d-none d-lg-block"
                style={{ bottom: "25%", right: "-17%" }}
                data-cue="fadeIn"
                data-delay="1500"
              ></div>
              <div data-cues="slideInDown" data-group="page-title">
                <h1 className="display-1 fs-64 mb-5 mx-md-10 mx-lg-0">
                  A membership club for <br />
                  <span className="rotator-fade text-primary">
                    BTC To MOON, BOB holders on Genify
                  </span>
                </h1>
                <p className="lead fs-24 mb-8">
                  {connected ? (
                    <span>
                      Welcome , your wallet{" "}
                      <span style={{ color: "#f8c300" }}>
                        {formatShortAddress(address)}
                      </span>{" "}
                      is connected to {network}
                    </span>
                  ) : (
                    <span>
                      "BOB: BlockOnBlock" is the first collection of generative
                      art on Bitcoin network
                    </span>
                  )}
                </p>
              </div>
              <div
                className="d-flex justify-content-center"
                data-cues="slideInDown"
                data-delay="600"
              >
                {getClickButton()}
              </div>
            </div>
          </div>
        </div>
      </section>

      {connected && (
        <section className="wrapper bg-soft-primary" id="demos">
          <div className="container pt-16 pt-mb-18">
            <div className="row mb-10">
              <div className="col-md-9 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                <div className="counter-wrapper">
                  <h3 className="fs-70 mb-3 text-primary text-center counter">
                    {moonCount}
                  </h3>
                </div>
                <h2 className="display-2 mb-3 text-center mb-0 px-xxl-8">
                  BTC TO MOON Inscriptions
                </h2>
              </div>
            </div>

            <div className="demos-wrapper text-center mb-16 mb-md-18">
              <h2 className="fs-17 text-uppercase text-muted mb-6">
                Membership
              </h2>
              <div className="row mb-10 gx-md-8 gy-12">{moonListHtml}</div>
            </div>

            <div className="row mb-10">
              <div className="col-md-9 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                <div className="counter-wrapper">
                  <h3 className="fs-70 mb-3 text-primary text-center counter">
                    {btcCount}
                  </h3>
                </div>
                <h2 className="display-2 mb-3 text-center mb-0 px-xxl-8">
                  BTC Inscriptions
                </h2>
              </div>
            </div>
            <div className="demos-wrapper text-center mb-16 mb-md-18">
              <h2 className="fs-17 text-uppercase text-muted mb-6">Valuable</h2>
              <div className="row mb-10 gx-md-8 gy-12">{btcListHtml}</div>
            </div>
            <div className="row mb-10">
              <div className="col-md-9 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                <div className="counter-wrapper">
                  <h3 className="fs-70 mb-3 text-primary text-center counter">
                    {bobCount}
                  </h3>
                </div>
                <h2 className="display-2 mb-3 text-center mb-0 px-xxl-8">
                  BOB Inscriptions
                </h2>
              </div>
            </div>
            <div className="demos-wrapper text-center mb-16 mb-md-18">
              <h2 className="fs-17 text-uppercase text-muted mb-6">Valuable</h2>
              <div className="row mb-10 gx-md-8 gy-12">{bobListHtml}</div>
            </div>
            <div className="row mb-10">
              <div className="col-md-9 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                <div className="counter-wrapper">
                  <h3 className="fs-70 mb-3 text-primary text-center counter">
                    {loomCount}
                  </h3>
                </div>
                <h2 className="display-2 mb-3 text-center mb-0 px-xxl-8">
                  Star Loom Inscriptions
                </h2>
              </div>
            </div>
            <div className="demos-wrapper text-center mb-16 mb-md-18">
              <h2 className="fs-17 text-uppercase text-muted mb-6">Valuable</h2>
              <div className="row mb-10 gx-md-8 gy-12">{loomListHtml}</div>
            </div>
          </div>
        </section>
      )}
      {showModal && (
        <div
          className="modal show"
          style={{ display: "block" }}
          id="modal-codes"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content text-center">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                ></button>
                <h2 className="mb-3 text-start">My White List Code</h2>
                <p className="lead mb-6 text-start">
                  Copy codes below to mint on Genify
                </p>
                <form className="text-start mb-3">
                  {getCodesHtml(codes)}
                  {getCountCodesHtml(countCodes)}
                  <a
                    onClick={() => setShowModal(false)}
                    className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  >
                    Close
                  </a>
                </form>
                <p class="mb-1"><a href={"https://btc.genify.xyz/collection/"+seqNo} target="_blank" class="hover">Go to Genify to mint</a></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default VerifyComponent;
