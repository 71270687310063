import { useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import { lang_map } from "../../lib/constant";
import MenuItem from "./MenuItem";
import SITE_LOGO from "../../assets/img/logo-purple.png";
import BTCTOMOON_LOGO from "../../assets/img/btctomoon.png";
import { Link } from "react-router-dom";
function Header(props) {
  const [lang, setLang] = useState("en-US");

  useEffect(() => {
    setLang(window.localStorage.getItem("lang") || intl.options.currentLocale);
  }, [lang]);

  const parseLangItems = () => {
    return Object.keys(lang_map).map((key, index) => {
      const id = "lang-" + index;
      const mkey = "lang-" + index;
      return (
        <li className="nav-item">
          <a
            id={id}
            key={mkey}
            className="dropdown-item"
            onClick={(e) => {
              setLanguage(key);
            }}
          >
            {lang_map[key]}
          </a>
        </li>
      );
    });
  };
  const setLanguage = (lang) => {
    window.localStorage.setItem("lang", lang);
    window.location.reload();
  };
 
  const parseChains = (chains) => {
    return chains.map((chain, index) => {
      const url = "/document/" + chain.symbol;
      //return <li><a className="dropdown-item" href="./docs/index.html">{chain.name}<span className="badge gradient-2 rounded">{chain.symbol}</span></a></li>
      return (
        <li>
          <Link className="dropdown-item" to={url}>
            {chain.name}
            <span className="badge gradient-2 rounded">{chain.symbol}</span>
          </Link>
        </li>
      );
    });
  };
   

  const langItems = parseLangItems();
 

  var headerClass = "wrapper " + props.bgclass;
  return (
    <header className={headerClass}>
      <nav className="navbar navbar-expand-lg center-logo transparent position-absolute navbar-dark">
        <div className="container justify-content-between align-items-center">
          <div className="d-flex flex-row w-100 justify-content-between align-items-center d-lg-none">
            <div className="navbar-brand">
              <a href="/">
                <img className="logo-dark" style={{"height":"80px"}}  src={BTCTOMOON_LOGO} alt="" />
                <img className="logo-light" style={{"height":"80px"}} src={BTCTOMOON_LOGO}   alt=""/>
              </a>
            </div>
            <div className="navbar-other ms-auto">
              <ul className="navbar-nav flex-row align-items-center">
                <li className="nav-item d-lg-none">
                  <button className="hamburger offcanvas-nav-btn">
                    <span></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-collapse-wrapper d-flex flex-row align-items-center w-100">
            <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
              <div className="offcanvas-header mx-lg-auto order-0 order-lg-1 d-lg-flex px-lg-15"> 
              <a href="/">
                <img className="logo-dark" style={{"height":"80px"}} src={BTCTOMOON_LOGO}   alt=""/>
                <img className="logo-light" style={{"height":"80px"}} src={BTCTOMOON_LOGO}   alt=""/>
               </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
 
}

export default Header;
