import { useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import BTCTOMOON_LOGO from "../../assets/img/btctomoon.png";

function Footer(props) {

    const className = props.bgclass + " text-inverse"
    return <footer className={className}>
        <div className="container pt-12 pt-lg-6 pb-13 pb-md-15">
            <div className="d-lg-flex flex-row align-items-lg-center">
                <h3 className="display-3 mb-6 mb-lg-0 pe-lg-20 pe-xl-22 pe-xxl-25 text-white">BOB with "BTC to Moon", Join  to get pass code</h3>
                <a href="/dashboard" className="btn btn-gradient gradient-2 rounded mb-0 text-nowrap">Get started</a>
            </div>

            <hr className="mt-11 mb-12" />
            <div className="row gy-6 gy-lg-0">
                <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <img className="mb-4" style={{"marginLeft":"30px","height":"50px"}} src={BTCTOMOON_LOGO} />
                        <p className="mb-4">© 2024 BOB <br className="d-none d-lg-block" /></p>
                        <nav className="nav social social-white">
                            <a href="https://twitter.com/shaun8149" target="_blank"><i className="uil uil-twitter"></i></a> 
                        </nav>

                    </div>

                </div>

                <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">BOB: BlockOnBlock</h4>
                        <address className="pe-xl-15 pe-xxl-17">The first collection of generative art on Bitcoin network</address>
                        <a target="_blank" href="https://btc.genify.xyz/collection/1">BOB on Genify</a>
                    </div> 
                </div> 
                 

            </div>

        </div>
    </footer>

}
export default Footer;
