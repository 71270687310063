import intl from "react-intl-universal";
import { lang_map } from './lib/constant';
const locales = {
    "zh-CN": require("./locales/zh-CN.json"),
    "en-US": require("./locales/en-US.json"),
};
export const loadLocales = () => {
    let currentLocale = intl.determineLocale({
        localStorageLocaleKey: "lang",
        cookieLocaleKey: "lang",
        urlLocaleKey: "lang",
    });
    if (!lang_map[currentLocale]) {
        currentLocale = "en-US";
    }
    window.localStorage.setItem("lang", currentLocale);
    return intl.init({
        currentLocale,
        locales,
    });
};