import logo from './logo.svg';
import './App.css';
//import UserTable from './components/UserList';
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { loadLocales } from './i18n';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./assets/css/plugins.css"
import "./assets/css/style.css"
import "./assets/css/colors/red.css"
import "./assets/css/fonts/urbanist.css"

import MainApp from './components/MainApp';
import Dashboard from './components/Dashboard';

function App() {
  loadLocales();
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route index element={<MainApp />} />
        <Route path="/dashboard"  Component={Dashboard} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
