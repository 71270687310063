import { useState, useContext, useEffect } from "react";
import intl from "react-intl-universal"; 
import { API_HOST } from "../../lib/constant";

function Services(props) {
  const [moonList, setMoonList] = useState([]);
  const [coverImg, setCoverImg] = useState({});
  const [coverInscription, setCoverInscription] = useState("");
  const [recommendMoonList, setRecommendMoonList] = useState([]);
  const [recommendListHtml, setRecommendListHtml] = useState("");

  useEffect(() => {
    let moonUrl = API_HOST + "/getBTCToMoonNFTList";
    const response = fetch(moonUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMoonList(data["data"]["list"]);
        let len = data["data"]["list"].length;
        let randIndex = Math.floor(Math.random() * data["data"]["list"].length);
        setCoverImg(data["data"]["list"][randIndex]);
        setCoverInscription(
          "https://unisat.io/inscription/" +
            coverImg["inscriptionId"]
        );
        //get 10 ones from the list
        let recommendList = [];
        for (let i = 0; i < 10; i++) {
          let randIndex = Math.floor(Math.random() * len);
          recommendList.push(data["data"]["list"][randIndex]);
        }
        setRecommendMoonList(recommendList);
        console.log(recommendList);
        const html = recommendList.map((moon) => {
            console.log("error");
            console.log(moon);
            var url = "https://unisat.io/inscription/" +  moon["inscriptionId"]
            return (
              <div className="col">
                <img
                  alt="dot"
                  className="mb-4"
                  src={moon.image}
                  height="128"
                  width="128"
                />
                <h4 style={{"color":"white"}}><a href={url} target="_blank">BOB {moon.tokenId}</a></h4>
              </div>
            );
          });
        setRecommendListHtml(html);
      });
  }, []);

  

  return (
    <section className="wrapper bg-navy">
      <div className="container pt-10 pt-md-14 pb-14 pb-md-16 text-center">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 mb-14 align-items-center">
          <div className="col-lg-7 order-lg-2" style={{ marginTop: "100px" }}>
            <figure>
              <img
                style={{ height: "30%" }}
                className="img-auto"
                src={coverImg["image"]}
                alt=""
              />
            </figure>
            <h3
              style={{
                marginTop: "20px",
                display: "inline",
                marginRight: "10%",
                color: "white",
              }}
            >
              <a href={coverInscription} target="_blank">
                {"#" + coverImg["inscriptionNumber"]}
              </a>
            </h3>
            <h3
              style={{
                marginTop: "20px",
                display: "inline",
                marginRight: "10%",
                color: "white",
              }}
            >
              {"BOB " + coverImg["tokenId"]}
            </h3>
          </div>
          <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
            <h1
              className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7"
              style={{ color: "white" }}
            >
              BOB <br className="d-md-none" />
              on Genify <br className="d-md-none" />
              <span className="rotator-fade text-primary">
                BTC To MOON Club
              </span>
            </h1>
            <p className="lead fs-lg mb-7">
              "BOB: BlockOnBlock" is the first collection of generative art on
              Bitcoin network
            </p>
            <span>
              <a href="/dashboard" className="btn btn-lg btn-gradient gradient-2 rounded me-2">
                Get Started
              </a>
            </span>
          </div>
        </div>
        <div className="row text-center pt-6  mb-15">
          <div className="col-lg-11 col-xl-10 col-xxl-9 mx-auto">
            <h2 className="fs-16 text-uppercase text-muted mb-3">Members</h2>
            <h3
              className="display-3 px-lg-12 px-xxl-14"
              style={{ color: "white" }}
            >
              Only <span className="underline-3 style-3 yellow">90</span> ones
              got <span className="underline-3 style-3 green">BTC TO MOON</span>{" "}
              style.
            </h3>
          </div>
        </div>
        <div
          className="row row-cols-2 row-cols-md-4 row-cols-lg-5 gx-md-8 gy-10 text-center justify-content-center"
          data-cues="slideInDown"
          data-group="features-3"
        >
          {recommendListHtml}
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="divider text-soft-primary mx-n2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
            <path
              fill="currentColor"
              d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
export default Services;
