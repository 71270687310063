

import React from "react";
import Header from "./common/Header"
import VerifyComponent from './dashboard/VerifyComponent';
import Footer from './common/Footer';
import Progress from "./mainapp/Progress";
//import Body from "./document/Body"
import { useParams } from "react-router-dom";

function Dashboard( ) {

    const params = useParams();
    console.log("params", params);



    return <div className="content-wrapper">

        <Header bgclass="bg-navy" />
        <VerifyComponent />       
        <Footer bgclass="bg-navy" />
        <Progress />

    </div>

}
export default Dashboard;